import Vue from 'vue';
import { createUserSession, getUserSession, updateUserSession } from './api';

export const getSession = async () => {
  const userSession = Vue.$cookies.get('user_session');
  return await getUserSession(userSession);
};

export const createSession = () => {
  const token = Vue.$cookies.get('user_session');

  createUserSession({
    token,
    data: {
      userInfo: {
        name: null,
        email: null
      },
      map: {
        search: null,
        params: null
      },
      adType: 'product',
      currentPage: 'home',
      booking: {
        panels: [],
        creativeId: null,
        dates: {
          start: null,
          end: null
        }
      }
    }
  });
};

export const updateSession = async (data: any = null) => {
  const token = Vue.$cookies.get('user_session');
  const userSession = (await getUserSession(token))[0];
  const userData = userSession.data;

  const json = { token, data: { ...userData, ...data } };
  updateUserSession(userSession.id, json);
};
