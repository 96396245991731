import { Slider } from '@/types/landing';
import { Place } from '@/types/mapbox-gl';
import { TemplateVariant, Template } from '@/types/template';
export interface FileHandler {
  source: string;
  name: string;
  type: string;
}

export interface Custom {
  logo: null | string;
  css: null | string;
  portraitTotem?: string;
  landscapeTotem?: string;
  landing: Slider;
}

export interface GlobalState {
  screens: Array<any>;
  template: TemplateVariant | null;
  rootTemplate: Template | null;
  media: FileHandler | null;
  templates: Array<any>;
  templateFields: Array<any>;
  amenities: Array<any>;
  fromDate: string | null;
  toDate: string | null;
  selectedCity: Place | null;
  stripe: any;
  stripeIntent: null | string;
  custom: Custom | null;
  adType: 'product' | 'event' | 'store' | 'private';
  progress: number;
  cancelTokenSource: any;
  search: string | null;
  creative: any;
  place: any;
  userInfo: any;
  config: any;
}

export const state: GlobalState = {
  screens: [],
  custom: null,
  amenities: [],
  fromDate: null,
  toDate: null,
  template: null,
  rootTemplate: null,
  adType: 'product',
  templateFields: [],
  config: {},
  media: null,
  templates: [],
  selectedCity: null,
  stripe: null,
  stripeIntent: null,
  progress: 0,
  cancelTokenSource: null,
  search: null,
  creative: null,
  place: null,
  userInfo: {
    name: null,
    email: null
  }
};
