








import { Prop, Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class ToggleButton extends Vue {
  @Prop() private readonly value!: boolean | number;
  private changeValue() {
    // @ts-ignore
    this.$emit("input", !this.value);
  }
}
