import { DirectiveOptions } from "vue";

const clickOutside: DirectiveOptions = {
  bind: function(el, binding, vnode) {
    //@ts-ignore
    el.clickOutsideEvent = function(event) {
      if (!(el == event.target || el.contains(event.target))) {
        if (binding.value && typeof binding.value === "function") {
          binding.value();
        }
        //@ts-ignore
        vnode.context[binding.arg] = false;
      }
    };
    //@ts-ignore
    document.body.addEventListener("click", el.clickOutsideEvent);
  }
};

export default clickOutside;
