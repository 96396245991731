
















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Button extends Vue {
  @Prop({ type: String, default: 'Loading...' }) private readonly text!: string;
  @Prop({ type: String, default: '' }) private readonly styles!: string;
  @Prop({ type: String, default: null }) private readonly tabindex!: string;
  @Prop({ type: String, default: '' }) private readonly dataCy!: string;
  @Prop({ type: [String, Object], default: '' }) private readonly classes!: string;
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean;
  @Prop({ type: Boolean, default: true }) private readonly show!: boolean;
  @Prop({ type: Boolean, default: true }) private readonly reference!: boolean;
}
