export default {
  hideInStreet: true,
  hidePlaceSearch: true,
  hideTypeHomepage: true,
  checkout: {
    defaultCountry: 'fr',
    vat: 20
  },
  broadcast: {
    noAdvancedFilters: true
  },
  creativeStudio: {
    lockOrientation: true,
    videoSpecs: {
      size: 50, // (MB)
      format: ['mp4', 'mov', 'avi'],
      duration: 10, // Time in second
      resolutions: ['1080 x 1920']
    }
  },
  splashText:
    '<p>Avec notre solution personnalis&eacute;e, nous nous adaptons &agrave; vos moyens et vos objectifs pour une campagne publicitaire en toute s&eacute;r&eacute;nit&eacute;.</p><p>D&eacute;marquez-vous dans nos centres commerciaux d&egrave;s 40€ par jour*.<br/><small><i>*Selon lieux et disponibilités</i></small></p>',
  i18n: {
    fr: {
      _globals: {
        upload: 'Téléchargez votre propre vidéo',
        upload_image: 'Téléchargez image',
        confirmed: 'Disponibilité à confirmer',
        create: 'Créez',
        broadcast: 'Choisissez',
        checkout: 'Validez',
        selected_panels: 'Écrans sélectionnés',
        panel_counter: "Pas d'écran | {count} écran | {count} écrans"
      },
      home: {
        where: 'Commencer ma campagne publicitaire'
      },
      creative: {
        title: 'Réalisez gratuitement votre spot de 10s',
        sendYourVideo: 'Importez votre vidéo'
      },
      campaign: {
        no_panel_title: "Pas d'écran sélectionné",
        select_dates_panels: 'Veuillez sélectionner vos dates et un écran',
        no_panel_cta: "Cliquez sur un point sur la carte pour ajouter l'écran à votre sélection",
        panel_select: 'Sélectionnez un écran',
        title:
          'Sélectionnez les dates de votre future campagne et le ou les écrans sur lesquels communiquer'
      },
      summary: {
        panel_counter: "Pas d'écran | écran | écrans",
        total_reach: "Diffusions",
        promo_code: 'Code promotionnel'
      }
    }
  },
  colors: {
    primary: {
      verylight: '#FFEAD3',
      light: '#F29A83',
      DEFAULT: '#f0713f',
      dark: '#D0511f'
    }
  }
};
