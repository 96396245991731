





















































import { Component, Vue } from 'vue-property-decorator';
import registeredComponents from '@/ModalRegistrations';
import { ModalButton, ModalData } from '@/types/Modal';

@Component({
  components: registeredComponents
})
export default class ModalBox extends Vue {
  private isOpen = false;
  private template = '';
  private externalData: any = null;
  private componentData: any = null;
  private theme = 'light';
  private confirmButton: ModalButton = {};
  private hasFooter = true;
  private cancelButton: ModalButton = {};
  private isSubmittable = true;
  private canCancel = true;
  private confirm = false;
  private onConfirm = () => {};
  private onClose = () => {};

  public created(): void {
    this.$modal.$on('toggleValidation', (payload: boolean | null) => {
      this.isSubmittable = payload !== null ? payload : !this.isSubmittable;
    });
    this.$modal.$on('toggle', (payload: boolean | null) => {
      this.isOpen = payload !== null ? payload : !this.isOpen;
    });
    this.$modal.$on('load', (payload: ModalData) => {
      this.load(payload);
    });
    this.$modal.$on('update', (payload: any) => {
      this.update(payload);
    });
  }

  private get confirmButtonText() {
    return this.confirmButton.text || this.$t('_globals.confirm');
  }

  private get cancelButtonText() {
    return this.cancelButton.text || this.$t('_globals.cancel');
  }

  private confirmModal() {
    this.confirm = true;
    this.$wait.start('isButtonLoading');
  }

  private update({
    template = '',
    data = {},
    onClose = null,
    onConfirm = null,
    confirmButton = {},
    cancelButton = {}
  }) {
    const currentData = typeof this.externalData === 'object' ? this.externalData : {};

    if (template !== '') {
      this.confirm = false;
      this.template = template;
    }
    this.externalData = { ...data, ...currentData };
    this.confirmButton = { ...this.confirmButton, ...confirmButton };
    this.cancelButton = { ...this.cancelButton, ...cancelButton };
    //@ts-ignore
    if (typeof onClose === 'function') this.onClose = onClose;
    //@ts-ignore
    if (typeof onConfirm === 'function') this.onConfirm = onConfirm;

    this.$wait.end('isButtonLoading');
  }

  private load({
    data = null,
    template = '',
    shouldOpen = true,
    hasFooter = true,
    canSubmit = true,
    canCancel = true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClose = (payload?: any) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onConfirm = (payload?: any) => {},
    confirmButton = {},
    cancelButton = {}
  }: ModalData): void {
    this.$forceUpdate();
    this.$modal.stopListen('close');
    this.$modal.stopListen('confirm');
    if (template === '') {
      throw new Error('No template provided in modal box');
    } else if (this.$options.components && !(template in this.$options.components)) {
      throw new Error(`Template ${template} is not registered`);
    }
    // allow ending loading from previous modal
    this.$wait.end('isButtonLoading');
    this.confirm = false;
    this.hasFooter = hasFooter;
    this.externalData = data ? { ...data } : null;
    this.template = template;
    this.canCancel = canCancel;
    this.confirmButton = confirmButton;
    this.cancelButton = cancelButton;
    this.onClose = onClose;
    this.onConfirm = onConfirm;
    this.$modal.listen('close', payload => {
      this.$modal.stopListen('close');
      this.$modal.stopListen('confirm');
      //@ts-ignore
      this.onClose(payload);
    });
    this.$modal.listen('confirm', payload => {
      this.$modal.stopListen('close');
      this.$modal.stopListen('confirm');
      //@ts-ignore
      this.onConfirm(payload);
    });

    this.$modal.$emit('toggle', shouldOpen);
    this.$modal.$emit('toggleValidation', canSubmit);
  }
  private close(): void {
    this.$wait.end('isButtonLoading');
    this.$forceUpdate();
    this.$modal.$emit('toggle', false);
    this.$modal.$emit('close');
  }
  public beforeDestroy() {
    this.$modal.stopListen('close');
    this.$modal.stopListen('confirm');
  }
}
