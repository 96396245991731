





















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class NewCheckBox extends Vue {
  @Prop({ required: true }) private readonly value!: boolean | number;
  @Prop({ required: false, default: false }) private readonly undetermined!: boolean | number;
  @Prop({ required: false, default: true }) private readonly valueClickUndetermined!:
    | boolean
    | number;
  private toggleValue() {
    this.$emit('input', this.undetermined ? this.valueClickUndetermined : !this.value);
  }
}
