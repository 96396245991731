import { GetterTree } from 'vuex';
import { GlobalState } from '@/store/state';
import dayjs from 'dayjs';

export const getters: GetterTree<GlobalState, any> = {
  getSelectAmenities(state) {
    return state.amenities.map(amenity => amenity);
  },
  getScreensCount(state) {
    if (!state.screens) return 0;
    return state.screens.length;
  },
  getTotalPrice(state, getters) {
    if (!state.fromDate || !state.toDate) return null;
    return (
      state.screens.reduce((acc: any, curr: any) => (acc ?? 0) + (curr.price ?? 0), 0) *
      getters.getDateDiff
    );
  },
  getTotalReach(state, getters) {
    if (!state.fromDate || !state.toDate) return null;
    return (
      state.screens.reduce(
        (acc: any, curr: any) => (acc ?? 0) + (isNaN(curr.reach) ? 0 : curr.reach),
        0
      ) * getters.getDateDiff
    );
  },
  needRequestForQuote(state) {
    return state.screens.some((s: any) => s.source?.booking_type === 'on-demand');
  },
  getDateDiff({ fromDate, toDate }) {
    if (fromDate && toDate) {
      const from = dayjs(fromDate);
      const to = dayjs(toDate);
      let start = from.clone(), sundays = 0;
      if (process.env.VUE_APP_BUILD === 'imediacenter') {
        while(!start.isSame(to)) {
          if (start.day() === 0) sundays++;
          start = start.add(1, 'day');
        }
      }
      return to.diff(from, 'day') + 1 - sundays;
    }
    return null;
  },
  getFromDate({ fromDate }) {
    return fromDate ? dayjs(fromDate).format('DD/MM/YYYY') : null;
  },

  getToDate({ toDate }) {
    return toDate ? dayjs(toDate).format('DD/MM/YYYY') : null;
  }
};
