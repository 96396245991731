





























import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

interface Option {
  label: string | TranslateResult;
  value: string | null;
  selected?: boolean;
}
@Component({})
export default class Select extends Vue {
  @Prop({ required: false }) private readonly placeholder!: string;
  @Prop({ required: true }) private readonly options!: Option[];
  @Prop({ required: false, default: true })
  private readonly selectDefault!: boolean;
  @Prop({ required: false, default: false })
  private readonly hasSearch!: boolean;
  @Prop({ required: false, default: false })
  private readonly hasNotSpecified!: boolean;
  @Prop({ required: true }) private readonly value!: string | null;

  private currentValue: Option | null = null;
  private isOpen = false;
  private filter = '';
  private readonly optionHeight = 36;
  private readonly optionsMenuHeight = 250;
  private localOptions: Array<Option> = [];

  @Ref() filterInput?: HTMLElement;

  @Watch('value')
  private handleValue(newValue: string | number | undefined) {
    if (newValue === undefined) {
      this.$emit('input', null);
    }
    const matchingObject = this.localOptions.find(el => el.value === newValue);
    if (matchingObject !== undefined) this.changeValue(matchingObject);
  }

  @Watch('options')
  private handleOptions() {
    this.localOptions = [...this.options];
    if (
      this.isOpen &&
      !this.checkForValue() &&
      this.value !== null &&
      (this.localOptions || this.localOptions!.length <= 0)
    ) {
      this.changeValue(this.localOptions[0]);
    }
  }

  @Watch('isOpen')
  private isOpenChanging(value: any) {
    if (value === true && this.filterInput) {
      this.$nextTick(() => {
        this.filterInput!.focus();
        //@ts-ignore
        this.filterInput!.select();
      });
    }
  }

  private highlight(text: string) {
    const index = text.toLowerCase().indexOf(this.filter.toLowerCase());
    const filterLength = this.filter.length;
    if (filterLength && index >= 0) {
      return `${text.substring(0, index)}<strong>${text.substring(
        index,
        index + filterLength
      )}</strong>${text.substring(index + filterLength)}`;
    } else {
      return text;
    }
  }

  private get filteredOptions() {
    return this.localOptions.filter((o, i) => {
      if (o.label === undefined) {
        throw Error('Field "label" not found for index ' + i);
      }
      //@ts-ignore
      return o.label.toLowerCase().includes(this.filter.toLowerCase());
    });
  }

  private toggleOptions() {
    this.isOpen = !this.isOpen;
  }

  private changeValue(val: Option) {
    this.currentValue = val;
    this.isOpen = false;
    this.$emit(
      'input',
      this.currentValue && this.currentValue.value ? this.currentValue.value : null
    );
  }

  private get isOptionsOverflow() {
    return this.localOptions.length * this.optionHeight > this.optionsMenuHeight;
  }

  private checkForValue() {
    let selected = false;
    for (const o of this.localOptions) {
      const matchValue = this.value === o.value;
      // eslint-disable-next-line no-prototype-builtins
      const isElementSelected = o.hasOwnProperty('selected') && o.selected;
      if (matchValue || isElementSelected) {
        selected = true;
        this.changeValue(o);
        if (matchValue) break; // do not remove !
      }
    }
    return selected;
  }
  public created() {
    if (!this.options) throw Error('No option provided in Select');
    this.localOptions = [...this.options];
    if (this.filter === '' && this.hasNotSpecified) {
      this.localOptions.unshift({
        label: this.$t('components_select.not_specified'),
        value: null
      });
    }
    if (!this.checkForValue() && this.localOptions.length > 0) {
      this.selectDefault && this.localOptions && this.localOptions.length
        ? this.changeValue(this.localOptions[0])
        : this.$emit('input', null);
    }
  }
}
