import Vue from 'vue';
import Vuex from 'vuex';
import { GlobalState } from '@/store/state';
import { state } from '@/store/state';
import { mutations } from '@/store/mutations';
import { actions } from '@/store/actions';
import { getters } from '@/store/getters';

Vue.use(Vuex);

export interface Store {
  state: GlobalState;
  mutations: any;
  getters: any;
  actions: any;
  modules: any;
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions
});
