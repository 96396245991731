import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    const adElement = document.getElementById('ad-detector');
    const adElementStyle = getComputedStyle(adElement as HTMLElement, null);
    if (adElementStyle.display === 'none') {
      next({ name: 'AdsBlocked' });
    }
  }, 1500);
  next();
});

export default router;
