import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/styles/tailwind.css';
import i18n from './localization';
import Select from '@/components/Select.vue';
import Picker from '@/components/Picker.vue';
import Textarea from '@/components/Textarea.vue';
import Button from '@/components/Button.vue';
import clickOutside from '@/directives/clickOutside';
import Modal from '@/plugins/modal';
import VueWait from 'vue-wait';
import VueCookies from 'vue-cookies';
Vue.config.productionTip = false;
import analytics from './analytics';

// VIEWS COMPONENTS
import Header from '@/views/_includes/Header.vue';
import Footer from '@/views/_includes/Footer.vue';
import Autocomplete from '@/components/Autocomplete.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Checkbox from '@/components/Checkbox.vue';
import ToggleButton from '@/components/ToggleButton.vue';
import LogoSVG from '@/assets/img/logo.svg';
import Input from '@/components/Input.vue';
import Loader from '@/components/Loader.vue';
import Multiselect from 'vue-multiselect';

//@ts-ignore
import VCalendar from 'v-calendar';
import Vuelidate from 'vuelidate';
import dayjs from 'dayjs';

Vue.component('Select', Select);
Vue.component('Picker', Picker);
Vue.component('Textarea', Textarea);
Vue.component('Autocomplete', Autocomplete);
Vue.component('Button', Button);
Vue.component('ToggleButton', ToggleButton);
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.component('Input', Input);
Vue.component('Loader', Loader);
Vue.component('Checkbox', Checkbox);
Vue.component('LogoSVG', LogoSVG);
Vue.component('Header', Header);
Vue.component('Footer', Footer);
Vue.component('Multiselect', Multiselect);
Vue.use(VCalendar, {
  componentPrefix: 'v'
});
Vue.use(Modal);
Vue.mixin({
  methods: {
    dayjs(params: any) {
      return dayjs(params);
    }
  }
});
Vue.use(VueWait);
Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.directive('click-outside', clickOutside);
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

const tracesSampleRate = window.location.href === 'http://localhost:8080/' ? 0 : 0.2;
// Sentry.init({
//   Vue,
//   dsn: 'https://8fe3a5f03c5a43c0be77b6e5b582f755@o571332.ingest.sentry.io/5719318',
//   integrations: [new Integrations.BrowserTracing()],
//   environment: process.env.VUE_APP_ENVIRONMENT,
//   tracesSampleRate
// });

router.afterEach(() => {
  analytics.page();
});

const wait = new VueWait({ useVuex: true });
new Vue({
  i18n,
  router,
  store,
  wait,
  render: h => h(App)
}).$mount('#app');
