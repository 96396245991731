import { RouteConfig } from 'vue-router';
function lazyLoad(view: string) {
  return () => import(`@/views/${view}.vue`);
}

export const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'home',
    component: lazyLoad('Home')
  },
  {
    path: '/slider',
    name: 'slider',
    component: lazyLoad('Slider')
  },
  {
    path: '/ads-blocked',
    name: 'AdsBlocked',
    component: lazyLoad('AdsBlocked')
  },
  {
    path: '/payment-validation',
    name: 'paymentValidation',
    component: lazyLoad('PaymentValidation')
  },
  {
    path: '/no-place',
    name: 'noPlace',
    component: lazyLoad('NoPlace')
  },
  {
    path: '/payment-succeeded',
    name: 'paymentSucceeded',
    component: lazyLoad('Success')
  },
  {
    path: '/rfq-succeeded',
    name: 'rfqSucceeded',
    component: lazyLoad('SuccessRFQ')
  },
  {
    path: '/found-place',
    name: 'foundPlace',
    component: lazyLoad('FoundPlace')
  },
  {
    path: '/creative-studio',
    name: 'creativeStudio',
    component: lazyLoad('CreativeStudio')
  },
  {
    path: '/campaign-planner',
    name: 'broadcast',
    component: lazyLoad('Broadcast')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: lazyLoad('Checkout')
  },
  {
    path: '/*',
    name: 'e404',
    component: lazyLoad('E404')
  }
];
