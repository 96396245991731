import Vue from 'vue';
import { MutationTree } from 'vuex';
import { FileHandler, GlobalState } from '@/store/state';
import { Place } from '@/types/mapbox-gl';
const updateMarkers = (state: GlobalState) => {
  Array.from(document.getElementsByClassName('picked-screen')).forEach((m: any) => {
    m.classList.remove('picked-screen', 'bg-verylight');
  });
  state.screens.forEach((s: any) => {
    if (document.getElementById(s.id))
      document.getElementById(s.id)!.classList.add('picked-screen', 'bg-verylight');
  });
};
export const mutations: MutationTree<GlobalState> = {
  setCustom(state: GlobalState, payload: any) {
    state.custom = payload;
  },
  setAmenities(state: GlobalState, payload: any) {
    state.amenities = payload;
  },
  addScreen(state: GlobalState, payload: any) {
    state.screens.push(payload);
    updateMarkers(state);
  },
  setConfig(state: GlobalState, payload: any) {
    state.config = payload;
  },
  // setScreens(state: GlobalState, payload: any) {
  //   state.screens = payload;
  //   updateMarkers(state);
  // },
  addScreens(state: GlobalState, payload: any) {
    state.screens.push(...payload);
    updateMarkers(state);
  },
  removeScreen(state: GlobalState, index: number) {
    state.screens.splice(index, 1);
    updateMarkers(state);
  },
  setTemplate(state: GlobalState, payload: any) {
    state.template = payload;
  },
  setTemplates(state: GlobalState, payload: any) {
    state.templates = payload;
  },

  setTemplateFields(state: GlobalState, payload: any) {
    state.templateFields = payload;
  },

  setRootTemplate(state: GlobalState, payload: any) {
    state.rootTemplate = payload;
  },

  setSelectedCity(state: GlobalState, payload: Place) {
    state.selectedCity = payload;
  },
  setMedia(state: GlobalState, payload: FileHandler | null) {
    state.media = payload;
  },
  removeMedia(state: GlobalState) {
    state.media = null;
  },
  setStripeIntent(state: GlobalState, payload: string) {
    state.stripeIntent = payload;
  },
  setFromDate(state: GlobalState, payload: string) {
    state.fromDate = payload;
  },
  setToDate(state: GlobalState, payload: string) {
    state.toDate = payload;
  },
  setStripe(state: GlobalState, payload: any) {
    state.stripe = payload;
  },
  setAdType(state: GlobalState, payload: any) {
    state.adType = payload;
  },
  setSearch(state: GlobalState, payload: any) {
    state.search = payload;
  },
  setCreative(state: GlobalState, payload: any) {
    state.creative = payload;
  },
  setPlace(state: GlobalState, payload: any) {
    state.place = payload;
  },
  setUserInfo(state: GlobalState, payload: any) {
    state.userInfo = payload;
  },
  resetStore(state: GlobalState) {
    state.toDate = null;
    state.fromDate = null;
    state.stripeIntent = null;
    state.media = null;
    state.creative = null;
    state.screens = [];
    state.templateFields = [];
  }
};
