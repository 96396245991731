












import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';

@Component({ components: {} })
export default class Textarea extends Vue {
  @Prop({ required: true }) private readonly value!: null | string;
  @Prop({ required: false, default: null }) private readonly name!: null | string;
  @Prop({ required: false, default: null })
  private readonly textareaClass!: any;
  @Prop({ required: false, default: null }) private readonly placeholder!: null | string;
  @Prop({ required: false, default: false })
  private readonly isCounter!: boolean;
  @Prop({ required: false, default: 0 }) private readonly max!: number;

  private content = '';
  private isOpen = true;
  private get currentLength() {
    return this.content ? this.content.length : 0;
  }
  @Watch('content')
  private contentHandler(val: any) {
    this.$emit('input', val);
  }

  @Watch('value')
  private valueHandler(val: any) {
    this.content = val;
  }

  private created(): void {
    this.content = this.value ? this.value : '';
  }
}
