export default {
  checkout: {
    defaultCountry: 'fr'
  },
  broadcast: {
    noAdvancedFilters: true
  },
  creativeStudio: {
    defaultOrientation: 'landscape'
  }
};
