





















import { Component } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Vue from 'vue';
import { fetchLanding } from './helpers/common';
import * as Sentry from '@sentry/vue';
import { v4 as uuidv4 } from 'uuid';
import { createSession, getSession } from './helpers/session';
import { getCreative, getTemplates, getTemplateVariant } from './helpers/api';
//@ts-ignore
import config from '../config/index';

@Component({ components: { Modal } })
export default class App extends Vue {
  private async created() {
    // const panels = await searchPanels({ search: 'oxial' });
    // panels.forEach(async (panel: any) => {
    //   await updatePanels(panel.id, { booking_type: 'instant-booking' });
    // });
    this.$store.dispatch('getAmenities');
    //@ts-ignore
    if (config[process.env.VUE_APP_BUILD]) {
      //@ts-ignore
      this.$store.commit('setConfig', config[process.env.VUE_APP_BUILD]);
    }
    if (process.env.VUE_APP_BUILD === 'imediacenter') {
      this.$store.commit('setCustom', {
        logo:
          '/assets/img/yzy.png',
        portraitTotem: '/assets/img/temp/totem-overlay-imediacenter.png',
        css: process.env.VUE_APP_BUILD,
        landing: await fetchLanding(process.env.VUE_APP_BUILD).catch(e => {
          Sentry.captureException(e);
          return null;
        })
      });
    } else if (process.env.VUE_APP_BUILD === 'oxialive') {
      this.$store.commit('setCustom', {
        portraitTotem: '../assets/img/temp/totem-overlay.png'
      });
    }

    this.$store.commit(
      'setStripe',
      process.env.VUE_APP_STRIPE_PK ||
        'pk_test_51GwoSzGvq32xYqDEybLCOWAksekZJxOHcfswb73cVC9NremzYBHVEFosV06U9glN6rlXjqW0Ko0SGk5sQTKwkJfh00lgWIvDHB'
    );
    if (!this.$cookies.get('user_session')) {
      this.$cookies.set('user_session', uuidv4());
      createSession();
    }
    this.$wait.start('app-loading');
    // SET APPLICATION WITH USER SESSION DATA
    this.$store.dispatch('getConfig', this).then(() => {
      this.$wait.end('app-loading');
    });

    /* if (Vue.$cookies.get('user_session')) {
      let session = await getSession();
      //@ts-ignore
      if (session && session[0]) {
        session = session[0];
        /*this.$store.commit('setAdType', session?.data?.adType ?? 'product');
        this.$store.commit('setSearch', session?.data?.map.search ?? null);
        this.$store.commit('setPlace', session?.data?.map?.params ?? null);
        this.$store.commit('setCreative', session?.data?.booking?.creativeId ?? null);
        this.$store.commit('setToDate', session?.data?.booking?.dates?.end ?? null);
        this.$store.commit('setFromDate', session?.data?.booking?.dates?.start ?? null);
        this.$store.state.screens = session?.data?.booking?.panels;

        // @TODO to be improved
        // if (session?.data?.booking?.creativeId) {
        //   const creative = await getCreative(session?.data?.booking?.creativeId);
        //   console.log(creative);
        //   const template_variant = await getTemplateVariant(creative[0].template_variant);
        //   const templates = await getTemplates();
        //   let template = templates.find((t: any) => t.id === template_variant[0].template_id);
        //   template = {
        //     ...template,
        //     fields: creative[0].fields
        //   };
        //   this.$store.commit('setTemplate', template);
        // }

        this.$store.commit('setUserInfo', {
          name: session?.data?.userInfo?.name ?? null,
          email: session?.data?.userInfo?.email ?? null
        });
        if (session?.data?.currentPage && session?.data?.currentPage !== this.$route.name)
          this.$router.push({ name: session?.data?.currentPage });
      }
    } */
  }

  private mounted() {
    //See adsv3 for customisation on loadInterco eg: (window as any).loadIntercom({ "hide_default_launcher": !intercomRoute.value });
    (window as any).loadIntercom();
  }
}
