










import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Input extends Vue {
  @Prop({}) value!: string;
  @Prop({}) placeholder!: string;

  private ref: any = null;

  private created() {
    this.ref = this.placeholder + Math.random();
  }

  private emitValue(event: any) {
    this.$emit('input', event.target.value);
  }
}
