





































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

interface Option {
  name: string;
  value: any;
}

@Component({ components: {} })
export default class Autocomplete extends Vue {
  @Prop({ required: false, default: '' }) private readonly value: any;
  @Prop({ required: false, default: '' }) private readonly searchValue!: string;
  @Prop({ required: false, default: '' }) private readonly placeholder!: string;
  @Prop({ required: false, default: '' }) private readonly inputClass!: string;
  @Prop({ required: false, default: null }) private readonly onsubmit!: any;
  @Prop({ required: true }) private readonly options!: Array<Option>;
  @Prop({ required: false, default: true })
  private readonly clearOnSave!: boolean;
  @Prop({ required: false, default: false })
  private readonly isLoading!: boolean;

  private search = '';
  private isOpen = false;
  private timer: any = null;
  private debounce = 300;
  private dontUpdate = false;

  private onFocus() {
    if (!this.search) return;

    this.isOpen = true;
    this.$emit('search', this.search);
  }

  @Watch('searchValue', { immediate: true })
  private searchValueHandler(searchValue: string) {
    this.search = searchValue;
    this.dontUpdate = true;
    this.isOpen = false;
  }

  @Watch('value', { immediate: true, deep: true })
  private valueHandler(val: any) {
    if (!val || !val.length) this.isOpen = false;
  }
  @Watch('search', { deep: true })
  private searchIt(newValue: string, previousValue: string): void {
    if (!newValue) this.$emit('search', '');

    if (newValue === previousValue) return;
    if (this.dontUpdate) {
      this.dontUpdate = false;
      return;
    }
    if (!newValue.length) {
      clearTimeout(this.timer);
      this.isOpen = false;
      return;
    }
    if (typeof this.timer === 'function') clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.isOpen = true;
      this.$emit('search', this.search);
    }, this.debounce);
  }

  private close() {
    this.isOpen = false;
  }

  private sendAndReset(value: any) {
    if (!value) return;

    this.isOpen = false;
    if (this.onsubmit && this.options[0]) {
      this.search = '';
      this.onsubmit(this.options[0]);
    } else {
      this.$emit('save', value);
      this.$emit('input', value);
    }
  }
}
