import { ModalData } from '@/types/Modal';
export interface ModalBus extends Vue {
  close(): void;
  open(): void;
  toggle(): void;
  update(payload: any): void;
  emit(event: string, payload: any): void;
  load(payload: ModalData): void;
  listen(name: string, functionToCall: (payload: any) => any): void;
  stopListen(name: string): void;
  confirm(payload?: any): void;
  canSubmit(isSubmittable: boolean): void;
}

// Doc : https://gitlab.com/moondayapp/frontend/-/wikis/Tooling/Modals
export default function ModalPlugin(Vue: any): void {
  const modalBus = new Vue();
  Vue.prototype.$modal = modalBus;

  Vue.prototype.$modal.close = () => {
    modalBus.$emit('toggle', false);
  };

  Vue.prototype.$modal.open = () => {
    modalBus.$emit('toggle', true);
  };

  Vue.prototype.$modal.update = (payload: any) => {
    modalBus.$emit('update', payload);
  };

  Vue.prototype.$modal.emit = (event: string, payload: any = null) => {
    modalBus.$emit(event, payload);
  };

  Vue.prototype.$modal.canSubmit = (isSubmittable: boolean) => {
    modalBus.$emit('toggleValidation', isSubmittable);
  };

  Vue.prototype.$modal.listen = (name: string, functionToCall: (payload: any) => any) => {
    modalBus.$on(name, functionToCall);
  };

  Vue.prototype.$modal.stopListen = (name: string) => {
    modalBus.$off(name);
  };

  Vue.prototype.$modal.load = (payload: ModalData) => {
    modalBus.$emit('load', payload);
  };

  Vue.prototype.$modal.confirm = (payload?: any) => {
    modalBus.$emit('confirm', payload);
  };
}
