





























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import 'vue-range-component/dist/vue-range-slider.css';
//@ts-ignore
import VueRangeSlider from 'vue-range-component';

@Component({
  components: {
    VueRangeSlider
  }
})
export default class Slider extends Vue {
  @Prop({}) private data!: Array<any>;
  @Prop({}) private comparateKey!: string;
  @Prop({}) private city!: string;
  private value = [0, 100];
  private values: Array<number> = [];
  private min = 0;
  private max = 100;
  private bgStyle = {
    backgroundColor: 'rgba(243, 244, 246)'
  };
  private tooltipStyle = {
    backgroundColor: '#10755a',
    borderColor: '#10755a'
  };
  private bgColor = {
    backgroundColor: '#16A07B'
  };

  private getPerecentage(value: number) {
    return `${(value / this.maxValue) * 100}px`;
  }

  @Watch('value', { deep: true })
  private handleValue() {
    this.$emit('input', this.value);
  }

  private getValue(values: any) {
    const counts: any = {};
    values.forEach((value: number) => {
      counts[value] = (counts[value] || 0) + 1;
    });
    this.values = Object.values(counts);
  }

  private get maxValue() {
    return Math.max(...this.values);
  }

  private get averageValue() {
    let sum = 0;
    this.data.forEach(d => {
      sum += d[this.comparateKey];
    });
    return (sum / this.data.length).toFixed(2);
  }

  private created() {
    this.max = Math.max(...this.data.map(d => d[this.comparateKey]));
    this.min = Math.min(...this.data.map(d => d[this.comparateKey]));
    this.value = [this.min, this.max];
    const temp = this.data.map(d => d[this.comparateKey]).sort((a, b) => a - b);
    this.getValue(temp);
  }
}
