var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header flex items-center justify-between px-3 py-2 md:py-5 bg-white"},[_c('div',{staticClass:"logo w-24 xl:w-32"},[(_vm.$store.state.custom && _vm.$store.state.custom.logo)?_c('img',{attrs:{"src":_vm.$store.state.custom.logo,"alt":"Logo"}}):_c('LogoSVG',{staticClass:"hidden md:block"})],1),_c('div',{staticClass:"md:ml-8  w-full right"},[_c('Breadcrumbs',{staticClass:"bg-primary-light",attrs:{"progress":[
        {
          key: 'broadcast',
          isDone: _vm.$store.getters.getScreensCount && _vm.$store.state.fromDate && _vm.$store.state.toDate
        },
        { key: 'creativeStudio', isDone: _vm.$store.state.template || _vm.$store.state.media },
        { key: 'checkout' }
      ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }