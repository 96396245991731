import Analytics from 'analytics';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
const gloohEndpoint = 'https://in.analytics.glooh.media/events';

const analytics = Analytics({
  app: 'gloohads-vx',
  debug: true,
  plugins: [
    {
      name: 'glooh-track',
      page: ({ payload }: { payload: any }) => {
        axios
          .post(gloohEndpoint, {
            activation: `gloohads_${process.env.VUE_APP_BUILD}`,
            data: JSON.stringify(payload)
          })
          .then(response => {
            // console.log(response);
          })
          .catch(e => Sentry.captureException(e));
      },
      track: ({ payload }: { payload: any }) => {
        axios
          .post(gloohEndpoint, {
            activation: `gloohads_${process.env.VUE_APP_BUILD}`,
            data: JSON.stringify(payload)
          })
          .then(response => {
            // console.log(response);
          })
          .catch(e => Sentry.captureException(e));
      }
    }
  ]
});

export default analytics;
