




















































































































import { Prop, Component, Vue } from 'vue-property-decorator';

interface ProgressItem {
  key: string;
  isDone?: boolean;
  isViewed?: boolean;
}
@Component({})
export default class Breadcrumbs extends Vue {
  @Prop({ default: () => [] }) private readonly progress!: Array<ProgressItem>;
  private checkLabel(key: string) {
    if (this.progress.find((p: ProgressItem) => p.key === key && !!p.isDone)) {
      return 'primary done';
    } else if (key === this.$route.name) {
      return 'primary';
    }
    return 'gray-500';
  }

  private get currentPage() {
    return this.progress.findIndex(p => this.$route.name === p.key);
  }

  private next() {
    if (
      Number.isInteger(this.currentPage) &&
      this.currentPage !== -1 &&
      this.progress.length - 1 > this.currentPage
    ) {
      this.$router.push({ name: this.progress[this.currentPage + 1].key });
    }
  }
  private previous() {
    if (this.currentPage && this.currentPage !== -1) {
      this.$router.push({ name: this.progress[this.currentPage - 1].key });
    }
  }

  private checkRoute(name: string): string | undefined {
    if (this.$route.name === name) return 'pointer-events-none';
  }
}
