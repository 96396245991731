import Vue from 'vue';
import VueI18n from 'vue-i18n';
import fr from '@/localization/fr.json';
//@ts-ignore
import configs from '../../config';
import _ from 'lodash';
let messages = { fr };
if (configs[process.env.VUE_APP_BUILD]?.i18n) {
  messages = _.merge(messages, configs[process.env.VUE_APP_BUILD].i18n);
}
Vue.use(VueI18n);

export default new VueI18n({
  locale: 'fr',
  messages,
  silentTranslationWarn: true
});
