import { ActionContext, ActionTree, ActionPayload } from 'vuex';
import axios from 'axios';
import store from '../store/index';
import _ from 'lodash';
import { getConfig } from '@/helpers/api';

export const actions = {
  async getAmenities({ commit }: any) {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/shops`);
    if (!response.data) throw Error('Amenities were not found');
    const amenities = response.data.map((el: any) => el.amenity);
    commit('setAmenities', amenities);
  },
  async getConfig({ commit }: any, vueInstance: Vue) {
    const config = await getConfig();
    if (config?.colors) {
      const name = ['r', 'g', 'b'];
      for (const color in config.colors) {
        let index = 0;
        config.colors[color].forEach((c: string) => {
          document.documentElement.style.setProperty(color + '-' + name[index], c);
          index++;
        });
      }
    }
    // config
    if (config?.general) {
      commit('setConfig', _.merge(store.state.config, config.general));
    }
    // translations
    if (config?.translations) {
      for (const lang in config.translations) {
        const currentLocale = vueInstance.$i18n.getLocaleMessage(lang);
        const merged = _.merge(currentLocale, config.translations[lang]);
        vueInstance.$i18n.setLocaleMessage(lang, merged);
      }
    }
    commit('setConfig', { ...store.state.config });
  }
};
