import axios from 'axios';
import * as Sentry from '@sentry/vue';
interface Headers {
  Authorization?: string;
}

const defaultHeaders: Headers = {};

const fetchApi = async (
  path = '',
  method: 'get' | 'post' | 'patch' = 'get',
  data: any = null,
  headers: Headers = defaultHeaders,
  root = '/items',
  isStripe = false
) => {
  const url = `${
    isStripe ? process.env.VUE_APP_BACKEND_ENDPOINT : process.env.VUE_APP_DIRECTUS_ENDPOINT
  }${root}${path}`;
  try {
    return (await axios({ method, url, data, headers })).data;
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const getLanding = async (landingName = '') => {
  return (await fetchApi(`/landing?search=${landingName}`)).data;
};

export const getPanels = async () => {
  let apiLink = `/panels?limit=-1&fields=*.*.*`;
  //If media owner restriction - we filter the templates
  if (process.env.VUE_APP_FILTER_SCREENS)
    apiLink = `/panels?limit=-1&filter={"provider": {"_in": ${process.env.VUE_APP_FILTER_SCREENS}}}&fields=*.*.*`;

  return (await fetchApi(apiLink)).data;
};

export const searchPanels = async (params: any = {}) => {
  if (params.search) {
    return (await fetchApi(`/panels?search=${params.search}`)).data;
  }
  return [];
};

export const updatePanels = async (id: number, data: any) => {
  return await fetchApi(`/panels/${id}`, 'patch', data);
};

export const getConfig = async () => {
  try {
    const apiLink = `/config?filter[key][_eq]=${process.env.VUE_APP_BUILD}`;
    return (await fetchApi(apiLink)).data[0];
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

export const getTemplates = async () => {
  try {
    let apiLink = `/templates`;
    //If media owner restriction - we filter the templates
    if (process.env.VUE_APP_FILTER_TEMPLATES)
      apiLink = `/templates?filter={"available": {"_in": ${process.env.VUE_APP_FILTER_TEMPLATES}}}`;

    return (await fetchApi(apiLink)).data;
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
};

export const getTemplatesVariants = async (id: string) => {
  return (await fetchApi(`/templates_variants?filter[template_id][_eq]=${id}`)).data;
};
export const getTemplateVariant = async (id: string) => {
  return (await fetchApi(`/templates_variants?filter[id][_eq]=${id}`)).data;
};

export const getTemplate = async () => {
  return (await fetchApi('/templates')).data;
};

export const getPOIs = async () => {
  return (await fetchApi('/pois')).data;
};

export const createCreative = async (data: any) => {
  return await fetchApi('/creatives', 'post', data);
};

export const createPayment = async (data: any) => {
  return await fetchApi('/stripe/createPayment', 'post', data, {}, '', true);
};

export const createRFQ = async (data: any) => {
  return await fetchApi('/requestForQuote', 'post', data, {}, '', true);
};

export const createValidation = async (data: any) => {
  return await fetchApi('/stripe/confirmPayment', 'post', data, {}, '', true);
};

export const getCreative = async (id: number) => {
  return (await fetchApi('', 'post', `/creatives?filter[id][_eq]=${id}`)).data;
};
export const createUser = async (data: any) => {
  return await fetchApi('/users', 'post', data);
};

export const getUser = async (email: any) => {
  return (await fetchApi(`/users?filter[email][_eq]=${email}`, 'get', '', {}, '')).data;
};

export const createUserSession = async (data: any) => {
  return await fetchApi(`/users_sessions/`, 'post', {
    token: data.token,
    data: data.data
  });
};

export const updateUserSession = async (id: number, data: any) => {
  return await fetchApi(`/users_sessions/${id}`, 'patch', {
    token: data.token,
    data: data.data
  });
};

export const getUserSession = async (id: any) => {
  return (await fetchApi(`/users_sessions?filter[token][_eq]=${id}`)).data;
};
