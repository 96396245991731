








import Vue from 'vue';

export default class Loader extends Vue {}
