import { getLanding } from './api';

export const fetchLanding = async (name: string) => {
  const landing = await getLanding(name);
  if (landing.length) {
    const { slider } = landing[0];
    if (!slider.length) return;
    return slider[0];
  }
  return;
};
